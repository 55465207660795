/* eslint-disable */
import { Currency, Pair } from '@noahswap/sdk'
import { Button, ChevronDownIcon, Text, useModal, Flex, Box } from '@noahswap/uikit'
import styled, { css } from 'styled-components'
import { isAddress } from 'utils'
import { useTranslation } from '@noahswap/localization'
import { WrappedTokenInfo } from '@noahswap/tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
// import { useEUSDCurrencyAmount } from 'hooks/useEUSDPrice'
import { formatNumber } from 'utils/formatBalance'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

import { Input as NumericalInput } from './NumericalInput'
import { CopyButton } from '../CopyButton'
import AddToWalletButton from '../AddToWallet/AddToWalletButton'

const InputRow = styled.div<{ selected: boolean; between: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: ${({ between }) => (between ? 'space-between' : 'flex-end')};
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 1rem 1rem' : '0.75rem 0.75rem 1rem 1rem')};
`
const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })<{ zapStyle?: ZapStyle }>`
  padding: 0 0.5rem;

  ${({ zapStyle, theme }) =>
    zapStyle &&
    css`
      padding: 8px;
      background: ${theme.colors.background};
      border: 1px solid ${theme.colors.cardBorder};
      border-radius: ${zapStyle === 'zap' ? '0px' : '8px'} 8px 0px 0px;
      height: auto;
    `};
`
const LabelRow = styled.div<{ width?: string }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  width: ${({ width }) => width || '100%'};
`
const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  /* background-color: ${({ theme }) => theme.colors.backgroundAlt}; */
  z-index: 1;
`
const Container = styled.div<{ zapStyle?: ZapStyle; error?: boolean }>`
  border-radius: 16px;
  background-color: #fff;
  padding: 20px 10px 0;
  box-shadow: ${({ theme, error }) => theme.shadows[error ? 'warning' : 'inset']};
  /* ${({ zapStyle }) =>
    !!zapStyle &&
    css`
      border-radius: 0px 16px 16px 16px;
    `}; */
`

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0.6;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
`

const BoxCls = styled(Box)`
  background-color: #fff;
  border-radius: 16px;
`

const InputPosition = styled.div`
  position: absolute;
  right: 0;
  top: 35px;
`

type ZapStyle = 'noZap' | 'zap'

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onInputBlur?: () => void
  onMax?: (num: any) => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  commonBasesType?: string
  zapStyle?: ZapStyle
  beforeButton?: React.ReactNode
  disabled?: boolean
  error?: boolean
  showEUSD?: boolean
  listData?: any
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onInputBlur,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  zapStyle,
  beforeButton,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
  commonBasesType,
  disabled,
  error,
  showEUSD,
  listData,
}: CurrencyInputPanelProps) {
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { t } = useTranslation()

  const token = pair ? pair.liquidityToken : currency?.isToken ? currency : null
  // const tokenAddress = token ? isAddress(token.address) : null

  // const amountInDollar = useEUSDCurrencyAmount(
  //   showEUSD ? currency : undefined,
  //   Number.isFinite(+value) ? +value : undefined,
  // )

  // console.log(currency, 'currency')

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
      commonBasesType={commonBasesType}
      listData={listData}
    />,
  )
  return (
    <BoxCls position="relative" id={id}>
      <InputPanel>
        <Container zapStyle={zapStyle} error={error}>
          {/* 选择框 */}
          <InputPosition>
            <Flex>
              {beforeButton}
              <CurrencySelectButton
                // zapStyle={zapStyle}
                className="open-currency-select-button"
                selected={!!currency}
                onClick={() => {
                  if (!disableCurrencySelect) {
                    onPresentCurrencyModal()
                  }
                }}
              >
                <Flex alignItems="center" justifyContent="space-between">
                  {pair ? (
                    <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                  ) : currency ? (
                    <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
                  ) : null}
                  {pair ? (
                    <Text id="pair">
                      {pair?.token0.symbol}:{pair?.token1.symbol}
                    </Text>
                  ) : (
                    <Text id="pair">
                      {(currency && currency.symbol && currency.symbol.length > 20
                        ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                            currency.symbol.length - 5,
                            currency.symbol.length,
                          )}`
                        : currency?.symbol) || t('noah142')}
                    </Text>
                  )}
                  {!disableCurrencySelect && <ChevronDownIcon />}
                </Flex>
              </CurrencySelectButton>
            </Flex>
          </InputPosition>
          {/* 输入框 */}
          <LabelRow width="75%">
            <NumericalInput
              align="left"
              fontSize="24px"
              error={error}
              disabled={disabled}
              className="token-amount-input"
              value={value}
              onBlur={onInputBlur}
              onUserInput={(val) => {
                onUserInput(val)
              }}
            />
            {/* test */}
          </LabelRow>
          <InputRow selected={disableCurrencySelect} between>
            <Text fontSize="12px" color="#4C4C4C" mr="12px">
              {id === 'swap-currency-input'
                ? t('noah40')
                : id.indexOf('add-liquidity-input') > -1
                ? t('noah52')
                : t('noah41')}
            </Text>
            <Flex>
              {account && (
                <Text
                  onClick={() => {
                    !disabled && onMax(selectedCurrencyBalance)
                  }}
                  color="#4C4C4C"
                  fontSize="14px"
                  style={{ display: 'inline', cursor: 'pointer' }}
                >
                  {!hideBalance && !!currency && selectedCurrencyBalance?.toSignificant(6)
                    ? `${t('noah38')}: ${selectedCurrencyBalance?.toSignificant(6)}`
                    : ''}
                </Text>
              )}
              {account && currency && !disabled && showMaxButton && label !== 'To' && (
                <Button
                  onClick={() => {
                    onMax(selectedCurrencyBalance)
                  }}
                  scale="xs"
                  color="#666"
                  style={{ marginLeft: '10px' }}
                >
                  {t('noah141')}
                </Button>
              )}
            </Flex>
          </InputRow>
        </Container>
        {disabled && <Overlay />}
      </InputPanel>
    </BoxCls>
  )
}
